<template scoped>
  <div>
    <el-form-item
      :rules="rules.service"
      label="Service"
      prop="connect.bluetooth.ble.service"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="logic.connect.bluetooth.ble.service"
        placeholder="00000000-0000-0000-0000-000000000000"
      >
        <el-switch
          slot="append"
          v-model="logic.connect.bluetooth.ble.serviceValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.characteristic"
      label="Characteristic"
      prop="connect.bluetooth.ble.characteristic"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="logic.connect.bluetooth.ble.characteristic"
        placeholder="00000000-0000-0000-0000-000000000000"
      >
        <el-switch
          slot="append"
          v-model="logic.connect.bluetooth.ble.characteristicValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>
    <el-form-item
      :rules="rules.descriptor"
      label="Descriptor"
      prop="connect.bluetooth.ble.descriptor"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="logic.connect.bluetooth.ble.descriptor"
        placeholder="00000000-0000-0000-0000-000000000000"
      >
        <el-switch
          slot="append"
          v-model="logic.connect.bluetooth.ble.descriptorValueType"
          active-text="Value"
          active-value="Value"
          inactive-text="Variable"
          inactive-value="Variable"
        ></el-switch>
      </el-input>
    </el-form-item>
    <el-form-item
      :rules="rules.descriptorMethod"
      label="Descriptor Method"
      prop="connect.bluetooth.ble.descriptorMethod"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        :style="{ width: '100%' }"
        v-model="logic.connect.bluetooth.ble.descriptorMethod"
        placeholder="Select the Descriptor Method"
      >
        <el-option
          v-for="item in supportDescriptorMethod"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
  },
  computed: {},
  methods: {
    validator_service: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the service should not be empty"));
      } else {
        callback();
      }
    },
    validator_descriptorMethod: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the descriptor method should not be empty"));
      } else {
        callback();
      }
    },
    validator_descriptor: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the descriptor should not be empty"));
      } else {
        callback();
      }
    },
    validator_characteristic: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the characteristic should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      supportDescriptorMethod: [
        {
          name: "Notification",
          value: "Notification",
        },
        {
          name: "Indication",
          value: "Indication",
        },
      ],
      rules: {
        service: [{ validator: this.validator_service, trigger: "blur" }],
        descriptorMethod: [
          { validator: this.validator_descriptorMethod, trigger: "blur" },
        ],
        descriptor: [{ validator: this.validator_descriptor, trigger: "blur" }],
        characteristic: [
          { validator: this.validator_characteristic, trigger: "blur" },
        ],
      },
    };
  },
};
</script>